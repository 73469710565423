
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Ref} from 'vue-property-decorator';
import {services, store} from '@/main';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import AlertService from '@/utils/AlertService';
import Password from '@/components/personnal-info/PwdDef.vue';
import {ITokenResponse} from '@/services/dto/IDtos';
import ErrorUtils from '@/utils/ErrorUtils';
import LoginService from '@/services/LoginService';
import {ILogin} from '@/services/dto/ILogin';

interface RedefinePasswordDto {
  login: string;
  password: string;
  tokenId: string;
}

@Component({
  components: {Password, SigInputTexte},
})
export default class MotDePasseOublie extends AbstractComponentVue {
  @Ref('pwd') private refPassword!: Password;

  // pour contenir l'email
  private password: string = '';
  private passwordValid: boolean = false;
  private login: string = this.$route.params.login;
  private token: string = this.$route.params.activationCode;
  private tokenValid: boolean = true;
  private tokenValidNotFound: boolean = false;
  private tokenResponse: ITokenResponse = {} as ITokenResponse;
  private isCollab: boolean = true;
  private isNotEntity: boolean = true;

  private created() {
    this.checkTokenAndLoadProhibitedWords();
  }

  /**
   * Fonction de vérification du token associé à l'utilisateur au chargement de la page
   */
  private checkTokenAndLoadProhibitedWords() {
    services.restService.callGet('/api/v1/tokens/' + this.token + '/' + this.login + '/check')
        .then((res) => {
          if (res.request.status === 404) {
            // On affiche que le token n'est plus valide
            this.tokenValid = false;
          } else {
            // On affiche le formulaire de saisie des informations.
            this.tokenValid = true;
            // On charge les données depuis le back
            this.tokenResponse = res.data;

            this.isCollab = res.data.roleUser === 'ROLE_SELFCARE_COLLABORATEUR';
            this.isNotEntity = (res.data.roleUser === 'ROLE_SELFCARE_COLLABORATEUR' || res.data.roleUser === 'ROLE_SELFCARE_NOTAIRE');

            // On charge les mots interdits dans les mdp
            const promiseProhibited: Promise<any> = services.restService
                .callGet('/api/v1/passwords/' + this.tokenResponse.accountId + '/prohibited-words');
            promiseProhibited.then(
                (d) => {
                  store.commit('loadProhibitedWords', d.data);
                },
            );
            LoginService.storePwdSpecialCharacters();
          }
        })
        .catch(() => {
          this.tokenValidNotFound = true;
          this.tokenValid = false;
          AlertService.error(ErrorUtils.msgDefault);
        });
  }

  private watchPasswordValidity(val: boolean) {
    this.passwordValid = val;
  }

  private notValideForm(): boolean {
    return this.passwordValid;
  }

  /**
   * fonction pour submit
   */
  private submitNewPassword(): void {
    if (this.passwordValid) {
      const redefinePasswordUrl = '/api/v1/accounts/' + this.tokenResponse.accountId + '/password';
      const newPasswordDto: RedefinePasswordDto = {
        login: this.login,
        password: this.refPassword.newPassword,
        tokenId: this.token,
      };
      const dataLogin: ILogin = {login: this.login, password: this.refPassword.newPassword};
      // appel REST pour le mdp oublie
      services.restService.callPost(redefinePasswordUrl, newPasswordDto)
          .then((resp: any) => {
            if (this.isNotEntity) {
              AlertService.success('La modification de votre mot de passe Mon Espace Real a bien été prise en compte.');
            } else {
              AlertService.success('La modification de votre mot de passe Mon Espace Real a bien été prise en compte. '
                  + 'Pensez également à informer les collaborateurs utilisant ce même compte Mon Espace Real.');
            }
            services.restService.callGet('/api/v1/connected-user/current')
                .then(services.loginService.loadUserPostProcessing);
            this.gotoHome();
          }).catch((resp) => {
        ErrorUtils.defaultCallback(resp);
      });
    }
  }

  private gotoHome() {
    this.$router.push('/');
  }

  private watchPassword(inputDto: ISigInputTextDto) {
    this.passwordValid = !inputDto.hasError;
    this.password = inputDto.value;
  }
}
